ul {
  list-style: none;
  padding: 0;
  margin: 0;
  /* background: #012c6d; */
  text-align: justify;
}

ul li {
  display: block;
  position: relative;
  float: left;
  /* background: #012c6d; */
}

.main-navigation .active {
  color: #fac46a !important;
}

li ul {
  display: none;
}

ul li a {
  display: block;
  padding: 1em;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
}

ul li a:hover,
ul li a:active,
ul li:active,
.mobileview ul li:active {
  /* background: #e0ab08; */
}

li:hover > ul {
  display: block;
  position: absolute;
  z-index: 9;
}

li:hover li {
  float: none;
  border-bottom: 1px solid #fff; /* Add a solid line after every sub-menu item */
}

li:hover li:last-child {
  border-bottom: none; /* Remove the border for the last sub-menu item */
}

.main-navigation li:hover a {
  color: #fac46a !important;
}
.main-navigation li ul {
  background-color: #ef6237 !important;
}

.main-navigation li ul li a:hover {
  color: white !important;
}
li:hover li a:hover {
  background: #e0ab08;
}

.main-navigation li ul li {
  border-top: 0;
}

ul ul ul {
  left: 100%;
  top: 0;
}

ul:before,
ul:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

ul:after {
  clear: both;
}

/*Mobile view Styless */

.dropdown-toggle::after {
  content: "";
  display: none;
}

.mobileview ul li:hover > ul {
  display: block;
  position: absolute;
  z-index: 9;
  width: 100%;
}

.mobileview ul li a:active {
  background: #e0ab08;
}
